import React from "react"
import "../styles/styles.scss"
import { Link } from "gatsby"
import "../styles/article-listing.scss"

const ArticleListing = (props) => {

  const article = props.data.map((item, index) =>
    <article key={index} className="article-container">
      <Link to={`/${item.node.elements.slug.value}`} className="article-heading">{item.node.elements.heading.value}</Link>
      <p className="article-description">{item.node.elements.metadata__description.value}</p>
      <span className="article-date">Date: {item.node.elements.date.value}</span>
    </article>
  )

  return (
    <section className="article-listing">
      {article}
    </section>
  )
}

export default ArticleListing